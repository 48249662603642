<template>
  <div class="center-g loginbox-g">
    <h3>绑定新手机</h3>
    <br />
    <el-form ref="regphoneForm" :model="regphoneForm" :rules="rules" hide-required-asterisk>
      <el-form-item prop="regphone">
        <el-input
          v-model="regphoneForm.regphone"
          placeholder="请输入要绑定的手机号"
          onkeyup="value=value.replace(/\D/g,'')"
          onafterpaste="value=value.replace(/\D/g,'')"
          maxlength="11"
        >
          <template slot="append">
            <div class="slotstyle">{{ regphoneForm.regphone.length }} / 11</div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="validCode">
        <el-input v-model.number="regphoneForm.validCode" placeholder="请输入验证码" maxlength="6">
          <template slot="append">
            <div class="slotstyle">
              <el-button type="success" @click="getCode" :disabled="btnBool">{{ this.yzmtext }}</el-button>
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-button type="success" @click="submitForm('regphoneForm')">提交</el-button>
    </el-form>
  </div>
</template>

<script>
import { validatePhone, sendPhone, bizUpdatePhone } from "@/api/api";
export default {
  data() {
    return {
      regphoneForm: {
        regphone: "",
        validCode: ""
      },
      yzmtext: "发送验证码",
      btnBool: false,
      rules: {
        regphone: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" }
        ],
        validCode: [
          { required: true, message: " 请输入验证码", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    biz() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    ValidationPhoneNumber() {
      let pattern = /^1[3456789]\d{9}$/;
      let test = pattern.test(this.regphoneForm.regphone);
      if (test) {
        return true;
      } else {
        this.$alert("请输入正确的手机号码", {
          confirmButtonText: "确定",
          type: "error",
          callback: () => {}
        });
      }
    },

    getCode() {
      const that = this;
      if (this.ValidationPhoneNumber()) {
        sendPhone({
          regphone: that.regphoneForm.regphone,
          type: 6
        }).then(res => {
          if (res.result) {
            that.$alert("验证码已发送", {
              confirmButtonText: "确定",
              type: "success",
              callback: () => {}
            });
            that.btnBool = true;
            let i = 60;
            let timer = setInterval(() => {
              that.yzmtext = i + "s " + "重发";
              i--;
              if (i < 0) {
                that.btnBool = false;
                that.yzmtext = "重新发送";
                clearInterval(timer);
              }
            }, 1000);
          }
        });
      }
    },
    bizUpdatePhoneFn() {
      const that = this;
      bizUpdatePhone({
        phone: that.regphoneForm.regphone
      }).then(res => {
        if (res.result) {
          localStorage.setItem("Authorization", "");
          that.$alert(
            "旧管理员微信已失效，请使用新手机号绑定的微信登录",
            "成功更改手机号",
            {
              type: "success",
              callback: () => {
                that.$router.push({ name: "login" });
              }
            }
          );
        } else {
          that.$alert(res.message, {
            type: "error",
            callback: () => {}
          });
        }
      });
    },
    submitForm(formName) {
      const that = this;
      if (!this.ValidationPhoneNumber()) {
        return;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          validatePhone({
            regphone: that.regphoneForm.regphone,
            vCode: that.regphoneForm.validCode
          }).then(res => {
            if (res.result) {
              that.bizUpdatePhoneFn();
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slotstyle {
  width: 70px;
  text-align: center;
}
</style>

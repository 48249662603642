import axios from "axios";
import router from '@/router';
import { Message } from 'element-ui'

const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: 'https://server.solar960.com',
    timeout: 30 * 1000,
    withCredentials: false
})

service.interceptors.request.use(
    config => {
        config.headers.Accept = 'application/x.server.v4+json;charset=utf8';
        if (localStorage.getItem('Authorization')) {
            config.headers.Authorization = localStorage.getItem('Authorization');
        }
        return config
    },
    err => Promise.reject(err)
)

service.interceptors.response.use(res => {
        if (res.data.result) {
            return res.data
        } else {
            Message({
                message: res.data.message,
                type: 'error',
                duration: 2000
            })
            return res.data
        }
    },
    err => {
        if (err.response.status == 401) {
            Message({
                message: '登录过期，请重新登陆',
                type: 'error',
                duration: 2 * 1000
            })
            router.push('/login')
        } else if (err.response.status == 500) {
            Message({
                message: '服务器异常，请稍后重试',
                type: 'error',
                duration: 2 * 1000
            })
        } 
        return Promise.reject(err)
    })

export default service
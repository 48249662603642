<template>
  <div>
    <el-page-header @back="back" content="设置新管理员手机号" class="pagehead-g"></el-page-header>
    <setphonetool />
  </div>
</template>

<script>
import setphonetool from "../components/SetphoneTool.vue";
export default {
  components: {
    setphonetool
  },
  methods: {
    back() {
      this.$router.go(-2);
    }
  }
};
</script>

<style>
</style>
import ajax from '@/utils/ajax'
// import qs from 'qs'

export function phoneLogin(data) {
    return ajax({
        url: `/api/biz/phoneLogin`,
        method: 'post',
        data
    })
}

export function checkLogin(data) {
    return ajax({
        url: `/api/checkLogin`,
        method: 'post',
        data
    })
}

export function sendPhone(data) {
    return ajax({
        url: `/api/sendPhone`,
        method: 'post',
        data
    })
}

export function bizLogin(data) {
    return ajax({
        url: `/api/biz/bizLogin`,
        method: 'post',
        data
    })
}

export function sightCompanyLogin(data) {
    return ajax({
        url: `/api/biz/sightCompanyLogin`,
        method: 'post',
        data
    })
}



export function createPromotion(data) {
    return ajax({
        url: `/api/promotion/createPromotion`,
        method: 'post',
        data
    })
}


export function getUrlScheme(data) {
    return ajax({
        url: `/api/promotion/getUrlScheme`,
        method: 'post',
        data
    })
}

//短信发券

export function sendSmsCoupons(data) {
    return ajax({
        url: `/api/promotion/sendSmsCoupons`,
        method: 'post',
        data
    })
}

//成员


export function getMembListBiz(data) {
    return ajax({
        url: `/api/user/getMembListBiz`,
        method: 'post',
        data
    })
}

//

export function getMessagePc(data) {
    return ajax({
        url: `/api/biz/getMessagePc`,
        method: 'post',
        data
    })
}

export function updateMessage(data) {
    return ajax({
        url: `/api/biz/updateMessage`,
        method: 'post',
        data
    })
}



export function delMessage(data) {
    return ajax({
        url: `/api/biz/delMessage`,
        method: 'post',
        data
    })
}

export function getAccountHistoryPc(data) {
    return ajax({
        url: `/api/user/getAccountHistoryPc`,
        method: 'post',
        data
    })
}


export function setBank(data) {
    return ajax({
        url: `/api/user/setBank`,
        method: 'post',
        data
    })
}

export function setInvoiceInfo(data) {
    return ajax({
        url: `/api/user/setInvoiceInfo`,
        method: 'post',
        data
    })
}

export function getInvoiceList(data) {
    return ajax({
        url: `/api/user/getInvoiceList`,
        method: 'post',
        data
    })
}

export function submitInvoiceHistory(data) {
    return ajax({
        url: `/api/user/submitInvoiceHistory`,
        method: 'post',
        data
    })
}


export function getQrcode(data) {
    return ajax({
        url: `/api/getQrcode`,
        method: 'post',
        data
    })
}

export function queryTicket(data) {
    return ajax({
        url: `/api/queryTicket`,
        method: 'post',
        data
    })
}

export function bizCash(data) {
    return ajax({
        url: `/api/biz/bizCash`,
        method: 'post',
        data
    })
}

export function createPaperCoupon(data) {
    return ajax({
        url: `/api/promotion/createPaperCoupon`,
        method: 'post',
        data
    })
}

export function submitSharelog(data) {
    return ajax({
        url: `/api/promotion/submitSharelog`,
        method: 'post',
        data
    })
}

export function getAuthRefund(data) {
    return ajax({
        url: `/api/refund/getAuthRefund`,
        method: 'post',
        data
    })
}

export function createWebPromotion(data) {
    return ajax({
        url: `/api/promotion/createWebPromotion`,
        method: 'post',
        data
    })
}

export function updateBizInfo(data) {
    return ajax({
        url: `/api/biz/updateBizInfo`,
        method: 'post',
        data
    })
}

export function bizGetCityList(data) {
    return ajax({
        url: `/api/biz/bizGetCityList`,
        method: 'post',
        data
    })
}

export function getServiceInfoPc(data) {
    return ajax({
        url: `/api/biz/getServiceInfoPc`,
        method: 'post',
        data
    })
}
export function setServiceInfoPc(data) {
    return ajax({
        url: `/api/biz/setServiceInfoPc`,
        method: 'post',
        data
    })
}
export function deleteServiceInfoPc(data) {
    return ajax({
        url: `/api/biz/deleteServiceInfoPc`,
        method: 'post',
        data
    })
}
export function updateWithDelteBiz(data) {
    return ajax({
        url: `/api/user/updateWithDelteBiz`,
        method: 'post',
        data
    })
}

export function getSystemInfo(data) {
    return ajax({
        url: `/api/getSystemInfo`,
        method: 'post',
        data
    })
}

export function validatePhone(data) {
    return ajax({
        url: `/api/validatePhone`,
        method: 'post',
        data
    })
}

export function bizUpdatePhone(data) {
    return ajax({
        url: `/api/biz/bizUpdatePhone`,
        method: 'post',
        data
    })
}


// ----------------------------------------------sight

export function deleteFileBiz(data) {
    return ajax({
        url: `/api/biz/deleteFileBiz`,
        method: 'post',
        data
    })
}

export function getOrderPc(data) {
    return ajax({
        url: `/api/sightpc/getOrderPc`,
        method: 'post',
        data
    })
}

export function getWalletPc(data) {
    return ajax({
        url: `/api/sightpc/getWalletPc`,
        method: 'post',
        data
    })
}

export function withdrawBizPc(data) {
    return ajax({
        url: `/api/sightpc/withdrawBizPc`,
        method: 'post',
        data
    })
}

export function updatePositionPc(data) {
    return ajax({
        url: `/api/sightpc/updatePositionPc`,
        method: 'post',
        data
    })
}
export function addPositionPc(data) {
    return ajax({
        url: `/api/sightpc/addPositionPc`,
        method: 'post',
        data
    })
}
export function getSightTypesPc(data) {
    return ajax({
        url: `/api/sightpc/getSightTypesPc`,
        method: 'post',
        data
    })
}
export function getSightDetailPc(data) {
    return ajax({
        url: `/api/sightpc/getSightDetailPc`,
        method: 'post',
        data
    })
}


export function getSightLiveInfoPC(data) {
    return ajax({
        url: `/api/sightpc/getSightLiveInfoPC`,
        method: 'post',
        data
    })
}
export function deleteSightLiveInfoPc(data) {
    return ajax({
        url: `/api/sightpc/deleteSightLiveInfoPc`,
        method: 'post',
        data
    })
}
export function addSightLiveInfoPc(data) {
    return ajax({
        url: `/api/sightpc/addSightLiveInfoPc`,
        method: 'post',
        data
    })
}
export function updateSightPc(data) {
    return ajax({
        url: `/api/sightpc/updateSightPc`,
        method: 'post',
        data
    })
}

export function updateTicketSell(data) {
    return ajax({
        url: `/api/sightpc/updateTicketSell`,
        method: 'post',
        data
    })
}

export function getTicketsPc(data) {
    return ajax({
        url: `/api/sightpc/getTicketsPc`,
        method: 'post',
        data
    })
}

export function getTicketMonthPricePc(data) {
    return ajax({
        url: `/api/sightpc/getTicketMonthPricePc`,
        method: 'post',
        data
    })
}

export function updateTicketPc(data) {
    return ajax({
        url: `/api/sightpc/updateTicketPc`,
        method: 'post',
        data
    })
}

export function setTicketShowPc(data) {
    return ajax({
        url: `/api/sightpc/setTicketShowPc`,
        method: 'post',
        data
    })
}

export function deleteTicketPc(data) {
    return ajax({
        url: `/api/sightpc/deleteTicketPc`,
        method: 'post',
        data
    })
}

export function addTicketPc(data) {
    return ajax({
        url: `/api/sightpc/addTicketPc`,
        method: 'post',
        data
    })
}
export function deletePriceRulePc(data) {
    return ajax({
        url: `/api/sightpc/deletePriceRulePc`,
        method: 'post',
        data
    })
}

export function addPriceRulePc(data) {
    return ajax({
        url: `/api/sightpc/addPriceRulePc`,
        method: 'post',
        data
    })
}

export function saveRulePricePc(data) {
    return ajax({
        url: `/api/sightpc/saveRulePricePc`,
        method: 'post',
        data
    })
}

export function getCommentsPc(data) {
    return ajax({
        url: `/api/sightpc/getCommentsPc`,
        method: 'post',
        data
    })
}
export function replyCommentPc(data) {
    return ajax({
        url: `/api/sightpc/replyCommentPc`,
        method: 'post',
        data
    })
}
export function getCompanyBasicPc(data) {
    return ajax({
        url: `/api/sightpc/getCompanyBasicPc`,
        method: 'post',
        data
    })
}



